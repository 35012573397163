
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { useEffect, useState } from 'react';
import { addApolloState, initializeApollo } from '@lib/apollo';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { HOME_TAB_TYPE } from '@customTypes/home';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import HomeHeader from '@/components/main/common/header/HomeHeader';
import HomeBannerWrapper from '@/components/main/common/banner/HomeBannerWrapper';
import HomeTab from '@/components/main/home/tab /HomeTab';
import HomeContainer from '@/components/main/home/HomeContainer';
import WeekDayContainer from '@/components/main/home/weekday/WeekDayContainer';
import AdultContainer from '@/components/main/home/adultTab';
import GenreContainer from '@/components/main/home/genreTab';
import { HomeStoryGenreItemFragment } from '@/operations/queries/home/__generated__/useHomeStoryGenres.generated';
import { useStoryGenreListQuery } from '@/operations/queries/home/__generated__/useStoryGenreList.generated';
import { useGetUserQuery } from '@/operations/queries/user/__generated__/getUser.generated';
import { ADULT_TAB, TABS_WITHOUT_ADULT } from '@/common/values';
import { useFetchGlobalConfig } from '@/hooks/useFetchGlobalConfig';

// 온보딩 스토리 없는 홈화면 페이지
const HomePage = () => {
  const router = useRouter();
  const { tab = HOME_TAB_TYPE.Home } = router.query;

  const [tabs, setTabs] = useState<HomeStoryGenreItemFragment[]>([]);
  const { data: userData } = useGetUserQuery({});
  const { data: genreData } = useStoryGenreListQuery();

  // 서버 점검중 상태 확인
  const { appStatus } = useFetchGlobalConfig();

  useEffect(() => {
    if (appStatus?.showMessage === true) {
      router.push('/error');
    }
  }, [appStatus]);

  useEffect(() => {
    if (
      userData?.getUser?.certificatedAt &&
      !userData.getUser.isExpiredCertification
    ) {
      setTabs([
        ...TABS_WITHOUT_ADULT,
        ADULT_TAB,
        ...(genreData?.homeStoryGenresV3 || []),
      ]);
    } else {
      setTabs([
        ...TABS_WITHOUT_ADULT,
        ...(genreData?.homeStoryGenresV3 || []),
        ADULT_TAB,
      ]);
    }
  }, [genreData, userData]);

  useEffect(() => {
    const ssoSignInStatus = localStorage.getItem('authStatus');
    const callbackUrl = localStorage.getItem('authCallback');
    const provider = localStorage.getItem('ssoProvider');

    if (ssoSignInStatus === 'pending' && callbackUrl && provider === 'apple') {
      router.push(callbackUrl);
    }
  }, []);

  return (
    <div css={container}>
      <HomeHeader tab="home" />
      <main className="main-container">
        <HomeTab tabs={tabs} />
        <HomeBannerWrapper />
        {tab === HOME_TAB_TYPE.Home && <HomeContainer />}
        {tab === HOME_TAB_TYPE.WeekDay && <WeekDayContainer />}
        {tab === HOME_TAB_TYPE.Adult && <AdultContainer />}
        {/* 장르 탭 */}
        {tab !== HOME_TAB_TYPE.Adult &&
          tab !== HOME_TAB_TYPE.WeekDay &&
          tab !== HOME_TAB_TYPE.Home && (
            <GenreContainer
              genreType={tab as string}
              genreName={
                tabs.find((item) => item.type === (tab as string))?.text
                  .ko_KR as string
              }
            />
          )}
      </main>
    </div>
  );
};

const container = css`
  width: 100%;
  max-width: ${theme.maxWidth};
  display: flex;
  align-items: center;

  .main-container {
    width: 100%;
    max-width: ${theme.maxWidth};
    min-height: 80rem;
    background: ${theme.colors.white};

    @media screen and (max-width: ${theme.devices.mobile}) {
      padding-top: 2.75rem;
    }
  }
`;

// TODO:
 const _getServerSideProps: GetServerSideProps = async (context) => {
  const client = initializeApollo();

  try {
    // await client.query({
    //   query: MONTHLY_STORY_LIST,
    //   context: getContextByLocale(context.locale),
    // });
    // await client.query({
    //   query: NEW_STORY_LIST,
    //   context: getContextByLocale(context.locale),
    // });

    // await client.query({
    //   query: GetAllBundleBannersDocument,
    //   context: getContextByLocale(context.locale),
    //   variables: {
    //     location: Banner_Location.Home,
    //   },
    // });
    return addApolloState(client, {
      props: {},
    });
  } catch (e) {
    return {
      notFound: true,
    };
  }
};

export default HomePage;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  